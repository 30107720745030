import React, {Fragment} from "react";
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import grafanaImg1 from '../../../../assets/images/bootcamp/trainings/iac/grafana-img1.jpg';
import grafanaImg2 from '../../../../assets/images/bootcamp/trainings/iac/grafana-img2.jpg';
import grafanaImg3 from '../../../../assets/images/bootcamp/trainings/iac/grafana-img3.jpg';

const prerequisites = [
    "Docker",
    "Kubernetes",
]

const topics = [
    "Grafana organizations & users",
    "Grafana sources, explorer, LogQL",
    "Grafana dashboards",
    "Grafana API",
    "Terraform Grafana provider",
    "A full example",
]

export const MonitoringSystem = () => <Training tag="Mid | Pro"
                                  id="grafana"
                                  title="Monitoring System / Grafana"
                                  imagesLeft={false}
                                  images={{
                                      first: {src: grafanaImg3, link: "", linkTitle: "", description: ""},
                                      second: {src: grafanaImg1, link: "", linkTitle: "", description: ""},
                                      third: {src: grafanaImg2, link: "", linkTitle: "", description: ""},
                                  }}
                                  description={<Fragment>
                                      <p className="text-muted para-desc mb-0">A must-have for services monitoring. <span
                                          className="text-primary fw-bold">Grafana</span> enables you to log every action of your microservices ecosystem.
                                      </p>
                                  </Fragment>}>

    <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
        <Description description="The training is suited for developers that are already familiar with:" />
        <Features features={prerequisites}/>
    </TrainingSection>

    <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
        <Description description="During the training the following topics will be covered:" />
        <Features features={topics}/>
    </TrainingSection>

    <TrainingSection title={"Goals"} icon={"uil-check-square"}>
        <Description description="After completion a trainee should be able to configure logging infrastructure, from user management to ingest and data visualization." />
    </TrainingSection>

</Training>