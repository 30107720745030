import React, {Fragment} from 'react'
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import ebaImg1 from '../../../../assets/images/bootcamp/trainings/eba/eba-img1.jpg';
import ebaImg6 from '../../../../assets/images/bootcamp/trainings/eba/eba-img6.jpg';
import ebaImg7 from '../../../../assets/images/bootcamp/trainings/eba/eba-img7.jpg';

const prerequisites = [
    "Software architectures",
    "Microservices",
    "Kafka",
    "Kafka connect"
]

const topics = [
    "Topics overview",
    "Treat topics like tables",
    "KSQL server",
    "KSQL streams",
    "KSQL tables",
    "KSQL queries",
    "KSQL migrations",
    "A full example"
]

export const KafkaKsql = () =>
    <Training tag="Mid | Pro"
              title="Kafka KSQL"
              id="kafka-ksql"
              imagesLeft={true}
              images={{
                  first: {src: ebaImg1, link: "", linkTitle: "", description: ""},
                  second: {src: ebaImg6, link: "", linkTitle: "", description: ""},
                  third: {src: ebaImg7, link: "", linkTitle: "", description: ""},
              }}
              description={<Fragment>
                  <p className="text-muted para-desc">Working with data in depth.</p>
                  <p className="text-muted para-desc mb-0">The <span
                      className="text-primary fw-bold">Kafka KSQL</span> training is focused on
                      understanding in detail how data flows and how can it be managed.</p>
              </Fragment>}>

        <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
            <Description description="The training is suited for developers that are already familiar with:"/>
            <Features features={prerequisites}/>
        </TrainingSection>

        <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
            <Description description="During the training the following topics will be covered:"/>
            <Features features={topics}/>
        </TrainingSection>

        <TrainingSection title={"Goals"} icon={"uil-check-square"}>
            <Description description="After completion a trainee should be familiar with KSQL and its core benefits."/>
        </TrainingSection>
    </Training>
