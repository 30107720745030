import React, {Fragment} from "react";
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import k8sHelmImg1 from '../../../../assets/images/bootcamp/trainings/iac/kubernetes-helm-img1.jpg';
import k8sHelmImg2 from '../../../../assets/images/bootcamp/trainings/iac/kubernetes-helm-img2.jpg';
import k8sHelmImg3 from '../../../../assets/images/bootcamp/trainings/iac/kubernetes-helm-img3.jpg';

const prerequisites = [
    "Yaml syntax",
    "Docker",
    "Code-First infrastructure",
    "SSL Certificates"
]

const topics = [
    "K8s api, cli, pods",
    "K8s pods resources, readiness, liveness",
    "K8s services - ip cluster, load balancer, node port",
    "K8s ingress, config maps, secrets",
    "K8s volumes, persistancy, snapshots",
    "K8s deployments, stateful sets, nodes, crd",
    "Helm projects, templates, conditions, loops",
    "Helm existent & custom functions",
    "Helm default, overwritten, release & custom variables",
    "Helm versioning, installs, upgrades, uninstalls",
    "Helm local & remote deps",
    "Helm tests",
    "A full example",
]

export const K8sAndHelm = () => <Training tag="Mid | Pro"
                                                  id="kubernetes"
                                                  title="Kubernetes & Heml"
                                                  imagesLeft={false}
                                                  images={{
                                                      first: {src: k8sHelmImg3, link: "", linkTitle: "", description: ""},
                                                      second: {src: k8sHelmImg1, link: "", linkTitle: "", description: ""},
                                                      third: {src: k8sHelmImg2, link: "", linkTitle: "", description: ""},
                                                  }}
                                                  description={<Fragment>
                                                      <p className="text-muted para-desc">Well suited for teams that is
                                                          looking for infrastructure flexibility & scalability.</p>
                                                      <p className="text-muted para-desc mb-0"><span
                                                          className="text-primary fw-bold">Helm</span> is taking it further, packing k8s resources into packages.
                                                          To be able to use logical decisions and more options used by the imperative paradigm,
                                                          to reuse already existent packages and test the deployed ones.
                                                      </p>
                                                  </Fragment>}>

    <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
        <Description description="The training is suited for developers that are already familiar with:" />
        <Features features={prerequisites}/>
    </TrainingSection>

    <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
        <Description description="During the training the following topics will be covered:" />
        <Features features={topics}/>
    </TrainingSection>

    <TrainingSection title={"Goals"} icon={"uil-check-square"}>
        <Description description="After completion a trainee should be able to manage dockerized deployment units - deployments, versioning, health checks." />
    </TrainingSection>

</Training>