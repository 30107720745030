import React, {Fragment} from "react";
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import sslServiceMeshImg1 from '../../../../assets/images/bootcamp/trainings/iac/ssl-service-mesh-img1.jpg';
import sslServiceMeshImg2 from '../../../../assets/images/bootcamp/trainings/iac/ssl-service-mesh-img2.jpg';
import sslServiceMeshImg3 from '../../../../assets/images/bootcamp/trainings/iac/ssl-service-mesh-img3.jpg';

const prerequisites = [
    "Yaml syntax",
    "Docker",
    "Code-First",
    "Kubernetes"
]

const topics = [
    "Cert-manager issuers",
    "Cert-manager cluster issuers",
    "Cert-manager certificates",
    "Cert-manager ingresses",
    "Istio gateways",
    "Istio destination rules",
    "Istio certificates consumption",
    "Istio mutual TLS",
    "Istio OpenID auth.",
    "Istio sidecar logs",
    "A full example",
]

export const CertificatesAndServiceMesh = () => <Training tag="Mid | Pro"
                                          id="servicemesh"
                                          title="Certificates & Service Mesh"
                                          imagesLeft={true}
                                          images={{
                                              first: {src: sslServiceMeshImg1, link: "", linkTitle: "", description: ""},
                                              second: {src: sslServiceMeshImg2, link: "", linkTitle: "", description: ""},
                                              third: {src: sslServiceMeshImg3, link: "", linkTitle: "", description: ""},
                                          }}
                                          description={<Fragment>
                                              <p className="text-muted para-desc">Well suited for infrastructure owners who deal with microservices clusters.</p>
                                              <p className="text-muted para-desc mb-0"><span
                                                  className="text-primary fw-bold">Service Mesh</span> is the solution for monitoring
                                                  all of the interactions within your micro services, restrict the traffic between your
                                                  apps and get rid of the duplicated authentication/authorization logic.
                                              </p>
                                          </Fragment>}>

    <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
        <Description description="The training is suited for developers that are already familiar with:" />
        <Features features={prerequisites}/>
    </TrainingSection>

    <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
        <Description description="During the training the following topics will be covered:" />
        <Features features={topics}/>
    </TrainingSection>

    <TrainingSection title={"Goals"} icon={"uil-check-square"}>
        <Description description="After completion a trainee should be able to handle certificates between the services and monitor the interactions within the cluster." />
    </TrainingSection>

</Training>