import React, {Component} from "react";
import {BootcampSessions} from "./bootcamp-sessions";
import {PostTraining} from "./post-training";
import {TeamAugumentation} from "./team-augumentation";
import {Header} from "./header";
import {Meta} from "./meta";

export default class Services extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "1",
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle(tab) {
        this.setState({activeTab: tab});
    }

    componentDidMount() {
        document.body.classList = "";
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.getElementById("topnav").classList.add("nav-sticky");
        } else {
            document.getElementById("topnav").classList.remove("nav-sticky");
        }
    };

    render() {
        return (
            <React.Fragment>
                <Header />
                <BootcampSessions />
                <PostTraining />
                <TeamAugumentation />
                <Meta />
            </React.Fragment>
        );
    }
}
