import React from "react";
import {Col, Card, CardBody, CardImg, Container, Row} from "reactstrap";

export const TrainingList = ({trainings, columnSplit}) => <section className="section pb-4">
    <Container>
        <Row>
            {trainings.map((training, key) => (
                <Col lg={columnSplit || "4"} md="6" className="mt-4 pt-2" key={key} name="blog">
                    <Card className="blog rounded border-0 shadow">
                        <div className="position-relative">
                            <CardImg top src={training.image} className="rounded-top" alt=""/>
                            <div className="overlay rounded-top bg-dark"></div>
                        </div>
                        <CardBody className="content">
                            <h5>
                                <a href={training.anchor} className="card-title title text-dark">
                                    {training.title}
                                </a>
                            </h5>
                            <div className="post-meta d-flex justify-content-between mt-3">
                                <a href={training.anchor} className="text-muted readmore">
                                    Read More <i className="mdi mdi-chevron-right"></i>
                                </a>
                            </div>
                        </CardBody>
                        <div className="author" style={{opacity: 0.7}}>
                            <small className="text-light user d-block">
                                {training.autor}
                            </small>
                        </div>
                    </Card>
                </Col>
            ))}
        </Row>
    </Container>
</section>