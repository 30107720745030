import React from 'react'
import {TrainingList} from "../shared/training-list";
import {images} from "./trainings/images";

const trainings = [
    {
        id: 1,
        image: images.dockerCodeFirst,
        anchor: "#docker",
        title: "Docker & Code First",
        autor: "Images, containers, volumes, networks, versioning...",
    },
    {
        id: 2,
        image: images.k8sHelm,
        anchor: "#kubernetes",
        title: "Kubernetes & Heml",
        autor: "Pods, resources, load balancers, config maps, secrets...",
    },
    {
        id: 3,
        image: images.sslServiceMesh,
        anchor: "#servicemesh",
        title: "Certificates & Service Mesh",
        autor: "Cert-manager certificates, issuers, Istio gateways, OpenID auth...",
    },
    {
        id: 4,
        image: images.terraform,
        anchor: "#terraform",
        title: "Terraform",
        autor: "Providers, provisioners, variables, remote-exec...",
    },
    {
        id: 5,
        image: images.jenkins,
        anchor: "#jenkins",
        title: "CI / Jenkins",
        autor: "Agents, plugins, pipelines, secrets, view, users, rbac, webhooks, CasC...",
    },
    {
        id: 6,
        image: images.grafana,
        anchor: "#grafana",
        title: "Monitoring System / Grafana",
        autor: "User management, resources, explorer, LogQL, dashboards, API, Terraform Grafana...",
    },
]
export const List = () => <TrainingList trainings={trainings}/>