import Landing from "./landing/index"
import Services from "./services/index"
import NotFound from "./not-found/index"
import JuniorKitTraining from "./trainings/junior-kit";
import ReactTraining from "./trainings/react";
import GraphqlTraining from "./trainings/graphql";
import IacTraining from "./trainings/iac";
import EbaTraining from "./trainings/eba";

const trainings = {
    juniorKit: {
        link: "/junior-kit",
        path: "/junior-kit",
        title: "Junior Kit",
        accent: "#f15a24",
        component: JuniorKitTraining,
        isTopbarDark: true
    },
    react: {
        link: "/react",
        path: "/react",
        title: "React JS",
        accent: "#fd78ad",
        component: ReactTraining,
        isTopbarDark: true
    },
    graphql: {
        link: "/graphql",
        path: "/graphql",
        title: "GraphQL",
        accent: "#9530d6",
        component: GraphqlTraining,
        isTopbarDark: true
    },
    iac: {
        link: "/infrastructure-as-code",
        path: "/infrastructure-as-code",
        title: "Infrastructure as Code",
        accent: "#6ada7a",
        component: IacTraining,
        isTopbarDark: true
    },
    eba: {
        link: "/event-based-architectures",
        path: "/event-based-architectures",
        title: "Event Based Architectures",
        accent: "#593ece",
        component: EbaTraining,
        isTopbarDark: true
    },
}

const external = {
    contact: {
        link: "https://nerds.sh/contact",
        path: "https://nerds.sh/contact",
        title: "Contact",
        isExternal: true,
    },
    mainSite: {
        link: "https://nerds.sh",
        path: "https://nerds.sh",
        title: "Main site",
        isExternal: true,
    },
    linkedIn: {
        title: "LinkedIn",
        link: "https://www.linkedin.com/company/nerdscomputing",
        isExternal: true,
    },
    blog: {
        title: "Blog",
        link: "https://nerds.sh/blog",
        isExternal: true,
    },
    privacyPolicy: {
        title: "Privacy Policy",
        link: "https://nerds.sh/privacy-policy",
        isExternal: true,
    },
    termsOfService: {
        title: "Terms of Services",
        link: "https://nerds.sh/terms-conditions",
        isExternal: true,
    },
    facebook: {
        title: "Facebook",
        link: "https://www.facebook.com/nerds.sh",
        isExternal: true,
    },
    youtube: {
        title: "Facebook",
        link: "https://www.youtube.com/channel/UC7BLoHcBWYMe_m8qq2KgbOQ",
        isExternal: true,
    },
    scheduleCall: {
        link: "https://calendly.com/nerds-daniel-schiau/nerds",
        title: "Schedule a call",
        isExternal: true,
    }
}

const internal = {
    landing: {
        link: "/",
        path: "/",
        title: "Home",
        component: Landing,
        isTopbarDark: true
    },
    services: {
        link: "/services",
        path: "/services", title: "Services",
        component: Services,
        isTopbarDark: true
    },
}

const notFound = {
    notFound: {
        component: NotFound,
        isWithoutLayout: true,
        exact: false
    },
}

export const routes = {
    ...trainings,
    ...internal,
    ...external,
    ...notFound,
}

