import dockerCodeFirst from '../../../../assets/images/bootcamp/trainings/iac/docker-code-first.jpg';
import k8sHelm from '../../../../assets/images/bootcamp/trainings/iac/kubernetes-helm.jpg';
import sslServiceMesh from '../../../../assets/images/bootcamp/trainings/iac/ssl-service-mesh.jpg';
import terraform from '../../../../assets/images/bootcamp/trainings/iac/terraform.jpg';
import jenkins from '../../../../assets/images/bootcamp/trainings/iac/jenkins.jpg';
import grafana from '../../../../assets/images/bootcamp/trainings/iac/grafana.jpg';

export const images = {
    dockerCodeFirst,
    k8sHelm,
    sslServiceMesh,
    terraform,
    jenkins,
    grafana,
}