import React from 'react'
import {Container} from 'reactstrap';
import {Junior} from "./trainings/junior";
import {Mid} from "./trainings/mid";
import {Pro} from "./trainings/pro";

export const Details = () => <section className="section">
    <Container>
        <Junior/>
        <Mid />
        <Pro />
    </Container>
</section>
