import React, {Fragment} from 'react'
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import graphQlImg1 from "../../../../assets/images/bootcamp/trainings/graphql/graphql-img1.jpg";
import graphQlImg5 from "../../../../assets/images/bootcamp/trainings/graphql/graphql-img5.jpg";
import graphQlImg6 from "../../../../assets/images/bootcamp/trainings/graphql/graphql-img6.jpg";

const prerequisites = [
    "GraphQL Fundamentals",
    "Basics of React.JS",
]

const topics = [
    "Data Loader",
    "Node Interface",
    "Connection Node",
    "Authorization",
    "Pagination",
    "Subscriptions",
    "Thinking in graphs"
]

export const Mid = () =>
    <Training tag="Mid"
              id="graphql-mid"
              title="Passionate Mid"
              imagesLeft={false}
              images={{
                  first: {src: graphQlImg1, link: "", linkTitle: "", description: ""},
                  second: {src: graphQlImg5, link: "", linkTitle: "", description: ""},
                  third: {src: graphQlImg6, link: "", linkTitle: "", description: ""},
              }}
              description={<Fragment>
                  <p className="text-muted para-desc">Ideal for developers with GraphQL experience but want to apply best practices and aim for more performant endpoints.</p>
              </Fragment>}>

        <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
            <Description description="The training is suited for developers that are already familiar with:"/>
            <Features features={prerequisites}/>
        </TrainingSection>

        <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
            <Description description="During the training the following topics will be covered:"/>
            <Features features={topics}/>
        </TrainingSection>

        <TrainingSection title={"Goals"} icon={"uil-check-square"}>
            <Description description="After completing this course a trainee should be capable of creating battle tested endpoint using GraphQL,
    having best-performances and practices-set."/>
        </TrainingSection>

    </Training>
