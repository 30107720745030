import React, {Fragment} from 'react'
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import graphQlImg1 from "../../../../assets/images/bootcamp/trainings/graphql/graphql-img1.jpg";
import graphQlImg7 from "../../../../assets/images/bootcamp/trainings/graphql/graphql-img7.jpg";
import graphQlImg8 from "../../../../assets/images/bootcamp/trainings/graphql/graphql-img8.jpg";

const prerequisites = [
    "GraphQL Practices",
    "Microservices",
]

const topics = [
    "Merge microservices in a single backend for frontend",
    "Modeling strategies",
]

export const Pro = () =>
    <Training tag="Mid"
              id="graphql-pro"
              title="Established Professional"
              imagesLeft={true}
              images={{
                  first: {src: graphQlImg1, link: "", linkTitle: "", description: ""},
                  second: {src: graphQlImg7, link: "", linkTitle: "", description: ""},
                  third: {src: graphQlImg8, link: "", linkTitle: "", description: ""},
              }}
              description={<Fragment>
                  <p className="text-muted para-desc">Perfect for developers that explore the software architectures beyond the ordinary.</p>
              </Fragment>}>

        <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
            <Description description="The training is suited for developers that are already familiar with:"/>
            <Features features={prerequisites}/>
        </TrainingSection>

        <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
            <Description description="During the training the following topics will be covered:"/>
            <Features features={topics}/>
        </TrainingSection>

        <TrainingSection title={"Goals"} icon={"uil-check-square"}>
            <Description description="After completing this course a trainee should be capable of creating a backend for frontend and merge
    existing microservices to service clients."/>
        </TrainingSection>

    </Training>
