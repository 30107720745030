import React from 'react'
import {TrainingList} from "../shared/training-list";
import {images} from "./trainings/images";

const trainings = [
    {
        id: 1,
        image: images.kafka,
        anchor: "#kafka",
        title: "Kafka",
        autor: "Coupling problem, Kafka topics, immutability, retention, scaling...",
    },
    {
        id: 2,
        image: images.kafkaConnect,
        anchor: "#kafka-connect",
        title: "Kafka Connect",
        autor: "Logic for another service, source & sink connectors...",
    },
    {
        id: 3,
        image: images.kafkaKsql,
        anchor: "#kafka-ksql",
        title: "Kafka KSQL",
        autor: "Treat topics like tables, ksqldb server, streams, tables, queries, migrations...",
    },
    {
        id: 4,
        image: images.eventBasedArch,
        anchor: "#event-based-arch",
        title: "Event Based Arch.",
        autor: "Code for others, topics for notifications, replication, querying...",
    },
]
export const List = () => <TrainingList trainings={trainings} columnSplit={3}/>