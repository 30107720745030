import React  from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

export const GettingStarted = () =>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-4 pb-2">
                <h2 className="mb-4">
                  <span className="text-primary fw-bold">
                      Getting started
                    </span>
                </h2>
                <p className="text-muted para-desc mx-auto mb-0">
                  Nerds {" "}
                  <span className="text-primary fw-bold">
                    Bootcamps
                  </span>{" "}
                  are focused on learning by doing. So it's essential that we outline a roadmap together with the client before actual training.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mt-4 pt-2">
              <Card className="features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-presentation-minus d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Discussion</h5>
                  <p className="text-muted mb-0">
                    The first step is to understand your company needs and trainees technical level on the specific technology.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-3 mt-4 pt-2">
              <Card className="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Strategy</h5>
                  <p className="text-muted mb-0">
                    We come to you with a training plan which is right for the team and then we agree together on the key points that have to be touched.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-5 mt-4 pt-2">
              <Card className="features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-presentation-play d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Training</h5>
                  <p className="text-muted mb-0">
                    Actual fun begins. Depending on the goals, the training may take between 2 and 5 days in which all the agreed topics will be covered.
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
