import React, {Fragment} from "react";
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import reactImg1 from '../../../../assets/images/bootcamp/trainings/react/react-img1.jpg';
import reactImg4 from '../../../../assets/images/bootcamp/trainings/react/react-img4.jpg';
import reactImg5 from '../../../../assets/images/bootcamp/trainings/react/react-img5.jpg';

const prerequisites = [
    "Web development / SPA concepts",
    "React Components",
    "React State Manegement"
]

const topics = [
    "Memoization",
    "Redux Design Pattern",
    "Basic understanding of RxJs",
    "Redux Observable",
    "Redux Persist",
    "Axios / Apollo",
    "Advanced understanding of React Router"
]

export const Mid = () => <Training tag="Mid"
                                   id="react-mid"
                                   title="Passionate Mid"
                                   imagesLeft={false}
                                   comingSoon={false}
                                   images={{
                                       first: {src: reactImg1, link: "", linkTitle: "", description: ""},
                                       second: {src: reactImg5, link: "", linkTitle: "", description: ""},
                                       third: {src: reactImg4, link: "", linkTitle: "", description: ""},
                                   }}
                                   description={<Fragment>
                                       <p className="text-muted para-desc">Well suited for developers that are
                                           familiar with modern web technologies but don't have necessary advanced
                                           know-how in React.</p>
                                       <p className="text-muted para-desc mb-0">The purpose of the <span
                                           className="text-primary fw-bold">mid</span> training is to enable the
                                           developer to apply best practices in a full-React environment.</p>
                                   </Fragment>}>

    <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
        <Description description="The training is suited for developers that are already familiar with:" />
        <Features features={prerequisites}/>
    </TrainingSection>

    <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
        <Description description="During the training the following topics will be covered:" />
        <Features features={topics}/>
    </TrainingSection>

    <TrainingSection title={"Goals"} icon={"uil-check-square"}>
        <Description description="After completion a trainee should be able to cover most business cases and handle
        complex state management systems." />
    </TrainingSection>

</Training>