import {Col, Container, Row} from "reactstrap";
import {routes} from "../routes";
import {ScheduleCall} from "../shared/components/schedule-call";

export const TeamAugumentation = () => <section className="section ">
    <Container className="mt-100 mt-60">
        <Row className="justify-content-center">
            <Col xs={12}>
                <div className="section-title text-center mb-4 pb-2">
                    <h4 className="title mt-3 mb-4">Team Augumentation</h4>
                    <p className="text-muted mx-auto">If fast delivery is key, our development team can
                        {" "}
                        <span className="text-primary fw-bold">
                        pair with your developers
                      </span>{" "}
                     and bootstrap your project. The objective is to upskill your team to the point where assistance
                    is no longer needed, and at the same time deliver on the project requirements.</p>
                </div>
            </Col>
        </Row>

        <Row className="justify-content-center">
            <Col lg={5} className="mt-2">
                <div className="px-md-4 pt-4">
                    <h4 className="mb-3">How we do this</h4>
                    <div className="px-md-4 pt-4 text-start">
                        <div className="d-flex mt-4">
                            <div className="flex-1 ms-2">
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-0">
                                            <span className="text-primary h5 me-2">
                                              <i className="uil uil-check-circle align-middle"></i>
                                            </span>
                                        Set up a solid foundation
                                    </li>
                                    <li className="mb-0">
                                            <span className="text-primary h5 me-2">
                                              <i className="uil uil-check-circle align-middle"></i>
                                            </span>
                                        Establish a way-of-work
                                    </li>
                                    <li className="mb-0">
                                            <span className="text-primary h5 me-2">
                                              <i className="uil uil-check-circle align-middle"></i>
                                            </span>
                                        Apply best practices for optimal delivery
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>

            <Col lg={5} className="mt-2">
                <div className="px-md-4 pt-4">
                    <h4 className="mb-3">Why should you do this</h4>
                    <div className="px-md-4 pt-4 text-start">
                        <div className="d-flex mt-4">
                            <div className="flex-1 ms-2">
                                <ul className="list-unstyled text-muted">
                                    <li className="mb-0">
                                            <span className="text-primary h5 me-2">
                                              <i className="uil uil-check-circle align-middle"></i>
                                            </span>
                                        Augment your team's capabilities
                                    </li>
                                    <li className="mb-0">
                                            <span className="text-primary h5 me-2">
                                              <i className="uil uil-check-circle align-middle"></i>
                                            </span>
                                        Build skills while delivering value
                                    </li>
                                    <li className="mb-0">
                                            <span className="text-primary h5 me-2">
                                              <i className="uil uil-check-circle align-middle"></i>
                                            </span>
                                        Get stuff done within deadlines
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>

            <Col lg={12} className="mt-4">
                <div className="watch-video mt-4 pt-2 text-center">
                    <ScheduleCall scheduleCallLink={routes.scheduleCall.link} text="Schedule a call" />
                </div>
            </Col>
        </Row>
    </Container>
</section>