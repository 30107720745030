import React from 'react'
import {routes} from "../../routes";
import { Row, Col } from "reactstrap";

export const MoreTrainings = () => <Row className="justify-content-center">
    <Col xs="12" className={"text-center"}>
        <a href={routes.landing.link}
           className="btn btn-primary btn-lg m-4">More trainings</a>
    </Col>
</Row>
