import React, {Fragment} from "react";
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import reactImg1 from '../../../../assets/images/bootcamp/trainings/react/react-img1.jpg';
import reactImg6 from '../../../../assets/images/bootcamp/trainings/react/react-img6.jpg';
import reactImg7 from '../../../../assets/images/bootcamp/trainings/react/react-img7.jpg';

const prerequisites = [
    "Javascript ecosystem",
    "Observable pattern",
    "Redux Design Pattern"
]

const topics = [
    "Advanced understanding of component lifecycles",
    "Suspense and Lazy Loading",
    "Portals and advanced DOM manipulation",
    "SEO",
    "Advanced understaning of WebPack and other bundlers",
    "Server Side Rendering",
    "Static Site Generation",
]

export const Pro = () => <Training tag="Professional"
                                   id="react-pro"
                                   title="Established Professional"
                                   imagesLeft={true}
                                   comingSoon={false}
                                   images={{
                                       first: {src: reactImg1, link: "", linkTitle: "", description: ""},
                                       second: {src: reactImg6, link: "", linkTitle: "", description: ""},
                                       third: {src: reactImg7, link: "", linkTitle: "", description: ""},
                                   }}
                                   description={<Fragment>
                                       <p className="text-muted para-desc">Best for developers with experience in
                                           React which want to push further the skill set around best practices
                                           caveats and performance tuning.</p>
                                       <p className="text-muted para-desc mb-0">Targeting established
                                           developers, <span
                                               className="text-primary fw-bold">professional</span> training's goal
                                           is to cover more advanced concepts.</p>
                                   </Fragment>}>

    <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
        <Description description="The training is suited for developers that are already familiar with:" />
        <Features features={prerequisites}/>
    </TrainingSection>

    <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
        <Description description="During the training the following topics will be covered:" />
        <Features features={topics}/>
    </TrainingSection>

    <TrainingSection title={"Goals"} icon={"uil-check-square"}>
        <Description description="After completion a trainee should be able to implement niche use cases and provide
        expert guidance th the team." />
    </TrainingSection>

</Training>