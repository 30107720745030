import React, {Fragment} from "react";
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import terraformImg1 from '../../../../assets/images/bootcamp/trainings/iac/terraform-img1.jpg';
import terraformImg2 from '../../../../assets/images/bootcamp/trainings/iac/terraform-img2.jpg';
import terraformImg3 from '../../../../assets/images/bootcamp/trainings/iac/terraform-img3.jpg';

const prerequisites = [
    "Code-First",
    "Kubernetes",
    "CI/CD",
]

const topics = [
    "Providers",
    "Provisioners",
    "Local & ENV variables",
    "Helm provider",
    "Local-exec & remote-exec provisioners",
]

export const Terraform = () => <Training tag="Mid | Pro"
                                                          id="terraform"
                                                          title="Terraform"
                                                          imagesLeft={false}
                                                          images={{
                                                              first: {src: terraformImg3, link: "", linkTitle: "", description: ""},
                                                              second: {src: terraformImg1, link: "", linkTitle: "", description: ""},
                                                              third: {src: terraformImg2, link: "", linkTitle: "", description: ""},
                                                          }}
                                                          description={<Fragment>
                                                              <p className="text-muted para-desc">Ideal for teams responsible for infrastructure handling at scale.</p>
                                                              <p className="text-muted para-desc mb-0"><span
                                                                  className="text-primary fw-bold">Terraform</span> enables you to prepare the whole infrastructure for further usage and be able to shut it down and re-create it without any headache at any time.
                                                              </p>
                                                          </Fragment>}>

    <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
        <Description description="The training is suited for developers that are already familiar with:" />
        <Features features={prerequisites}/>
    </TrainingSection>

    <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
        <Description description="During the training the following topics will be covered:" />
        <Features features={topics}/>
    </TrainingSection>

    <TrainingSection title={"Goals"} icon={"uil-check-square"}>
        <Description description="After completion a trainee should be able to set up a simple infrastructure using Terraform." />
    </TrainingSection>

</Training>