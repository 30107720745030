import {Col, Container, Row} from "reactstrap";
import {routes} from "../routes";

export const BootcampSessions = () => <section className="section">
    <Container className="mt-60">
        <Row className="justify-content-center">
            <Col xs={12}>
                <div className="section-title text-center pb-2">
                    <h4 className="title mt-3 mb-4">The Bootcamp Sessions</h4>
                </div>
            </Col>

            <Col lg={12}>
                <div className="flex-1 ms-2">
                    <p className="text-muted mx-auto">Because every training is customized
                        based on trainees level, company needs and extras, the fixed price is not
                        applicable. It all depends on the agreed strategy and we are {" "}
                        <span className="text-primary fw-bold">
                        100% transparent
                      </span>{" "} about it.</p>
                </div>
                <div className="watch-video mt-4 pt-2 text-center">
                    <a href={routes.landing.link} className="btn btn-primary m-1">Learn more</a>
                </div>
            </Col>
        </Row>


    </Container>
</section>