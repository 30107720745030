import React from "react";

export const Features = ({features}) =>
    <ul className="list-unstyled text-muted">
        {features.map((feature, index) => (<li key={index} className="mb-0">
            <span className="text-primary h5 me-2">
            <i className="uil uil-check-circle align-middle"></i>
            </span>
            {feature}
        </li>))}
    </ul>