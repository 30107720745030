import React, {Fragment} from "react";
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import jenkinsImg1 from '../../../../assets/images/bootcamp/trainings/iac/jenkins-img1.jpg';
import jenkinsImg2 from '../../../../assets/images/bootcamp/trainings/iac/jenkins-img2.jpg';
import jenkinsImg3 from '../../../../assets/images/bootcamp/trainings/iac/jenkins-img3.jpg';

const prerequisites = [
    "Yaml syntax",
    "Groovy syntax",
    "CI/CD",
    "Code-First",
    "Kubernetes",
    "Certificates"
]

const topics = [
    "Jenkins agents, plugins, pipelines, secrets, views",
    "Jenkins users, rbac",
    "Jenkins webhooks",
    "Jenkins k8s & dsl plugins",
    "Jenkins seed job, CasC",
    "A full example",
]

export const Ci = () => <Training tag="Mid | Pro"
                                                          id="jenkins"
                                                          title="CI / Jenkins"
                                                          imagesLeft={true}
                                                          images={{
                                                              first: {src: jenkinsImg1, link: "", linkTitle: "", description: ""},
                                                              second: {src: jenkinsImg3, link: "", linkTitle: "", description: ""},
                                                              third: {src: jenkinsImg2, link: "", linkTitle: "", description: ""},
                                                          }}
                                                          description={<Fragment>
                                                              <p className="text-muted para-desc">Ideal for teams that have Jenkins as CI tool.</p>
                                                          </Fragment>}>

    <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
        <Description description="The training is suited for developers that are already familiar with:" />
        <Features features={prerequisites}/>
    </TrainingSection>

    <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
        <Description description="During the training the following topics will be covered:" />
        <Features features={topics}/>
    </TrainingSection>

    <TrainingSection title={"Goals"} icon={"uil-check-square"}>
        <Description description="After completion a trainee should be able to build a Jenkins setup with advanced configuration." />
    </TrainingSection>

</Training>