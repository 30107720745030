import React, {Component} from 'react'

import {Header} from '../shared/header';
import {KeyDescription} from '../shared/key-description';

import bgImage from "../../../assets/images/bootcamp/trainings/react/bg.png";
import {TagLine} from "../shared/tagline";
import {Reviews} from "../../landing/Reviews";
import {MoreTrainings} from "../shared/more-trainings";
import {List} from "./list";
import {Details} from "./details";
import {GettingStarted} from "../shared/getting-started";
import {routes} from "../../routes";
import {Meta} from "./meta";


export default class ReactTraining extends Component {
    componentDidMount() {
        document.body.classList = "";
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.getElementById("topnav").classList.add("nav-sticky");
        } else {
            document.getElementById("topnav").classList.remove("nav-sticky");
        }
    };

    render() {
        return (
            <React.Fragment>
                <Header bgImage={bgImage} title={routes.react.title} accent={routes.react.accent} />
                <List />
                <TagLine />
                <Details/>
                <GettingStarted />
                <KeyDescription/>
                <MoreTrainings />
                <Reviews/>
                <Meta />
            </React.Fragment>
        )
    }
}
