import React, {Fragment} from 'react'
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import ebaImg1 from '../../../../assets/images/bootcamp/trainings/eba/eba-img1.jpg';
import ebaImg2 from '../../../../assets/images/bootcamp/trainings/eba/eba-img2.jpg';
import ebaImg3 from '../../../../assets/images/bootcamp/trainings/eba/eba-img3.jpg';

const prerequisites = [
    "Software architectures",
    "Microservices"
]

const topics = [
    "Coupling problem",
    "Coupling solution",
    "Kafka overview",
    "Kafka topics",
    "Kafka immutability",
    "Kafka retention",
    "A full example"
]

export const Kafka = () =>
    <Training tag="Mid | Pro"
              title="Kafka"
              id="kafka"
              imagesLeft={true}
              images={{
                  first: {src: ebaImg1, link: "", linkTitle: "", description: ""},
                  second: {src: ebaImg2, link: "", linkTitle: "", description: ""},
                  third: {src: ebaImg3, link: "", linkTitle: "", description: ""},
              }}
              description={<Fragment>
                  <p className="text-muted para-desc">Starting point for a robust and resilient architecture.</p>
                  <p className="text-muted para-desc mb-0">The <span
                      className="text-primary fw-bold">Kafka</span> training is focused on
                      understanding in detail how it works giving a starting point for the future
                      development.</p>
              </Fragment>}>

        <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
            <Description description="The training is suited for developers that are already familiar with:"/>
            <Features features={prerequisites}/>
        </TrainingSection>

        <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
            <Description description="During the training the following topics will be covered:"/>
            <Features features={topics}/>
        </TrainingSection>

        <TrainingSection title={"Goals"} icon={"uil-check-square"}>
            <Description description="After completion a trainee should be able to know the principles, mechanisms,
        setup Kafka  from scratch."/>
        </TrainingSection>
    </Training>
