import kafka from '../../../../assets/images/bootcamp/trainings/eba/kafka.jpg';
import kafkaConnect from '../../../../assets/images/bootcamp/trainings/eba/kafka-connect.jpg';
import kafkaKsql from '../../../../assets/images/bootcamp/trainings/eba/kafka-ksql.jpg';
import eventBasedArch from '../../../../assets/images/bootcamp/trainings/eba/event-based-arch.jpg';

export const images = {
    kafka,
    kafkaConnect,
    kafkaKsql,
    eventBasedArch,
}