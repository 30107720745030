import React, {Component, Suspense} from 'react';
import ScrollUpButton from "react-scroll-up-button";
import FeatherIcon from 'feather-icons-react';
import "./css/demo.css";
import {routes} from "../routes";
import {Reviews} from "./Reviews";
import {Trainings} from "./trainings";
import {HowItWorksSteps} from "./how-it-works-steps";
import {Meta} from "./meta";
import {ScheduleCall} from "../shared/components/schedule-call";


const Home = React.lazy(() => import('./Home'));
const Instructors = React.lazy(() => import('./Instructors'));
const Faqs = React.lazy(() => import('./Faqs'));

const KeyFeatures = React.lazy(() => import('./KeyFeatures'));
const Location = React.lazy(() => import('./Location'));



const CustomDot = () => {
    return (
        <i><FeatherIcon icon="arrow-up" className="icons"/></i>
    );
};

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        document.body.classList = "";
        window.addEventListener("scroll", this.scrollNavigation, true);
    }

    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.getElementById('topnav').classList.add('nav-sticky');
        } else {
            document.getElementById('topnav').classList.remove('nav-sticky');
        }
    }

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Suspense fallback={this.Loader()}>
                    <Home/>

                    <section className="section" id="trainings">
                        <Trainings/>
                    </section>
                    <h4 className="title mb-4 text-center" style={{color: "gray"}}>
                        Nerds&nbsp;
                        <span className="text-primary fw-bold">
                      Bootcamp
                    </span>
                        &nbsp;is the proven way for CTOs to upgrade their team's skills in a matter of days!
                    </h4>
                    <section className="section" id="our-approach">
                        <HowItWorksSteps/>
                    </section>
                    <KeyFeatures/>
                    <Reviews/>
                {/*    <section className="section" id="getting-started">
                        <WorkProcess/>
                    </section>*/}
                    <Location/>
                    <Instructors/>
                    <div className="text-center mt-100">
                        <ScheduleCall scheduleCallLink={routes.scheduleCall.link} text="Schedule a call" />
                    </div>
                    <Faqs/>
                    {/* scrollup button */}
                    <ScrollUpButton ContainerClassName="classForContainer" style={{height: 36, width: 36}}
                                    TransitionClassName="classForTransition">
                        <CustomDot/>
                    </ScrollUpButton>
                </Suspense>
                <Meta />
            </React.Fragment>
        );
    }
}

export default Index;




