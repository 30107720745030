import React, {Fragment} from "react";
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";

import reactImg1 from '../../../../assets/images/bootcamp/trainings/react/react-img1.jpg';
import reactImg2 from '../../../../assets/images/bootcamp/trainings/react/react-img2.jpg';
import reactImg3 from '../../../../assets/images/bootcamp/trainings/react/react-img3.jpg';

const prerequisites = [
    "HTML & CSS",
    "Javascript",
    "HTTP protocol"
]

const topics = [
    "Basic understanding of SPAs",
    "Components",
    "Component state management",
    "Effects",
    "Contexts",
    "Fetch API",
    "Custom Hooks",
    "Basic understanding of React Router"
]

export const Junior = () => <Training tag="Junior"
                                      id="react-junior"
                                      title="Ehusiastic Junior"
                                      imagesLeft={true}
                                      images={{
                                          first: {src: reactImg1, link: "", linkTitle: "", description: ""},
                                          second: {src: reactImg2, link: "", linkTitle: "", description: ""},
                                          third: {src: reactImg3, link: "", linkTitle: "", description: ""},
                                      }}
                                      description={<Fragment>
                                          <p className="text-muted para-desc">Ideal for developers that are familiar with
                                              the basic concepts about WEB development but are not familiar with any modern
                                              technologies.</p>
                                          <p className="text-muted para-desc mb-0">The <span
                                              className="text-primary fw-bold">junior</span> training is focused on
                                              understanding in detail how React works giving a starting point for the future
                                              development.</p>
                                      </Fragment>}>

    <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
        <Description description="The training is suited for developers that are already familiar with:" />
        <Features features={prerequisites}/>
    </TrainingSection>

    <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
        <Description description="During the training the following topics will be covered:" />
        <Features features={topics}/>
    </TrainingSection>

    <TrainingSection title={"Goals"} icon={"uil-check-square"}>
        <Description description="After completion a trainee should be able to know the principles, mechanisms,
        setup the environment and create a basic React application from scratch." />
    </TrainingSection>

</Training>