import React from 'react'
import {Container} from 'reactstrap';
import {Kafka} from "./trainings/kafka";
import {KafkaConnect} from "./trainings/kafka-connect";
import {EventBasedArch} from "./trainings/event-based-arch";
import {KafkaKsql} from "./trainings/kafka-ksql";

export const Details = () => <section className="section">
    <Container>
        <Kafka />
        <KafkaConnect />
        <KafkaKsql />
        <EventBasedArch />
    </Container>
</section>
