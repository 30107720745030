import React from 'react'
import {Container, Row} from 'reactstrap';
import BlogBox from "./BlogBox";
import javascript from '../../assets/images/bootcamp/technologies/javascript.jpg';
import react from '../../assets/images/bootcamp/technologies/react.jpg';
import graphql from '../../assets/images/bootcamp/technologies/graphql.jpg';
import kubernetes from '../../assets/images/bootcamp/technologies/kubernetes.jpg';
import kafka from '../../assets/images/bootcamp/technologies/kafka.jpg';
import {routes} from "../routes";

const getTrainings = () => [
    {
        id: 1,
        image: javascript,
        title: routes.juniorKit.title,
        autor: "A must-know pack for fresh developers.",
        link: routes.juniorKit.path,
    },
    {
        id: 2,
        image: react,
        title: routes.react.title,
        autor: "All about React. Multi-level approach, suitable for every junior, mid or pro developer.",
        link: routes.react.path,
    },
    {
        id: 3,
        image: graphql,
        title: routes.graphql.title,
        autor: "Granular API interaction for client-facing services.",
        link: routes.graphql.path,
    },
    {
        id: 4,
        image: kubernetes,
        title: routes.iac.title,
        autor: "Tools for a code-first infrastructure.",
        link: routes.iac.path,
    },
    {
        id: 5,
        image: kafka,
        title: routes.eba.title,
        autor: "Kafka for a performant, scalable and resilient architecture.",
        link: routes.eba.path,
    },
]

export const Trainings = () => <Container>
    <Row style={{justifyContent: "center"}}>
        <BlogBox blogs={getTrainings()}/>
    </Row>
</Container>
