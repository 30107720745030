import React, {Fragment} from 'react'
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import graphQlImg1 from "../../../../assets/images/bootcamp/trainings/graphql/graphql-img1.jpg";
import graphQlImg2 from "../../../../assets/images/bootcamp/trainings/graphql/graphql-img2.jpg";
import graphQlImg3 from "../../../../assets/images/bootcamp/trainings/graphql/graphql-img3.jpg";

const prerequisites = [
    "REST",
    "Basics of React.JS",
]

const topics = [
    "GraphQL Fundamentals",
    "Queries & Mutations - Fields, Arguments, Aliases, Fragments, Operation Name, Variables, Directives, Inline Fragments",
    "Schemas, Object Types & Fields",
    "Validation",
    "Resolvers",
    "Root entry resolver",
]

export const Junior = () =>
    <Training tag="Junior"
              id="graphql-junior"
              title="Enthusiastic Junior"
              imagesLeft={true}
              images={{
                  first: {src: graphQlImg1, link: "", linkTitle: "", description: ""},
                  second: {src: graphQlImg2, link: "", linkTitle: "", description: ""},
                  third: {src: graphQlImg3, link: "", linkTitle: "", description: ""},
              }}
              description={<Fragment>
                  <p className="text-muted para-desc">Well suited for developers that make the switch to GraphQL and are already familiar with some basic HTTP backend knowledge.</p>
              </Fragment>}>

        <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
            <Description description="The training is suited for developers that are already familiar with:"/>
            <Features features={prerequisites}/>
        </TrainingSection>

        <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
            <Description description="During the training the following topics will be covered:"/>
            <Features features={topics}/>
        </TrainingSection>

        <TrainingSection title={"Goals"} icon={"uil-check-square"}>
            <Description description="After completing this course, a trainee should be comfortable with creating, an api in graphql,
        and build a simple endpoint serving basic requests."/>
        </TrainingSection>

    </Training>
