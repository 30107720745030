import React from "react";
import {Col, Container, Row} from "reactstrap";

export const Header = ({bgImage, title, accent}) => <React.Fragment>
    <section
        className="bg-half bg-light d-table w-100"
        style={{background: `url(${bgImage}) center center`}}
    >
        <Container>
            <Row className="justify-content-center">
                <Col lg={12} className="text-center">
                    <div className="title-heading">
                        <h4 className="heading mt-2 mb-4">
                            <span className="training-title" style={{color: accent}} >{title}</span>
                        </h4>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
    <div className="position-relative">
        <div className="shape overflow-hidden text-white">
            <svg
                viewBox="0 0 2880 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                    fill="currentColor"
                ></path>
            </svg>
        </div>
    </div>
</React.Fragment>
