import React from 'react'
import {Container} from 'reactstrap'
import SectionTitle from '../../../components/Shared/SectionTitle';

export const KeyDescription = () => <Container className="mt-100 mt-60">
    <SectionTitle
        title="Carefully tailored sessions"
        desc={<React.Fragment>We know that <span
            className="text-primary fw-bold">is impossible to put the skills in a box</span>, that's why before every training an evaluation is made. There is no need force it, it can be a mix between them.</React.Fragment>}
    />
</Container>
