import React from "react";
import {Card, CardBody, Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {Cta} from "./cta";
import {routes} from "../../../routes";

export const Training = ({title, tag, id, imagesLeft, images, description, children, comingSoon}) =>
    <section className="section pt-0 pb-0" id={id}>
        <Row className="align-items-center">
            <Row className="pt-5 pb-5" style={{flexDirection: imagesLeft ? "row" : "row-reverse"}}>
                <Col lg={6} md={6}>
                    <Row className="align-items-center" style={{height: "100%"}}>
                        <Col lg={6} xs={6} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                            <Card className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                <CardBody className="p-0">
                                    <img src={images.first.src} className="img-fluid" alt=""/>
                                    <div className="overlay-work bg-dark"></div>
                                    <div className="content">
                                        <Link to={images.first.link}
                                              className="title text-white d-block fw-bold">{images.first.linkTitle}</Link>
                                        <small className="text-light">{images.first.description}</small>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6} xs={6}>
                            <Row>
                                <Col lg={12} md={12} className="mt-4 mt-lg-0 pt-2 pt-lg-0">
                                    <Card
                                        className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                        <CardBody className="p-0">
                                            <img src={images.second.src} className="img-fluid" alt=""/>
                                            <div className="overlay-work bg-dark"></div>
                                            <div className="content">
                                                <Link to={images.second.link}
                                                      className="title text-white d-block fw-bold">{images.second.linkTitle}</Link>
                                                <small className="text-light">{images.second.description}</small>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col lg={12} md={12} className="mt-4 pt-2">
                                    <Card
                                        className="work-container work-modern overflow-hidden rounded border-0 shadow-md">
                                        <CardBody className="p-0">
                                            <img src={images.third.src} className="img-fluid" alt=""/>
                                            <div className="overlay-work bg-dark"></div>
                                            <div className="content">
                                                <Link to={images.third.link}
                                                      className="title text-white d-block fw-bold">{images.third.linkTitle}</Link>
                                                <small className="text-light">{images.third.description}</small>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                <Col lg={6} md={6} className="mt-4 mt-lg-0 pt- pt-lg-0">
                    <div className="ms-lg-4">
                        <div className="section-title">
                            <span className="badge bg-soft-primary rounded-pill fw-bold">{tag}</span>
                            <h4 className="title mb-4 mt-3">{title}</h4>
                            {description}
                        </div>
                        {children}
                        <Cta contactLink={routes.contact.link} scheduleCallLink={routes.scheduleCall.link}/>
                    </div>
                </Col>

                {comingSoon && <div className="bg-overlay" style={{borderRadius: "20px", opacity: "0.5"}}></div>}
                {comingSoon && <h1 className="my-4 fw-bold" style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                    background: "white",
                    padding: "50px 150px",
                    width: "auto",
                    borderRadius: "20px",
                }}>Coming soon</h1>}
            </Row>
        </Row>
    </section>