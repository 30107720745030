import React, {Fragment} from 'react'
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import ebaImg1 from '../../../../assets/images/bootcamp/trainings/eba/eba-img1.jpg';
import ebaImg8 from '../../../../assets/images/bootcamp/trainings/eba/eba-img8.jpg';
import ebaImg9 from '../../../../assets/images/bootcamp/trainings/eba/eba-img9.jpg';

const prerequisites = [
    "Software architectures",
    "Microservices",
    "Kafka",
    "Kafka connect",
    "Kafka KSQL",
]

const topics = [
    "Code for others overview",
    "Code for others problem",
    "Use topics for notifications",
    "Use source connectors for replication",
    "Use sink connectors for replication",
    "Use ksqldb server for topic querying",
    "A full partial-failure example"
]

export const EventBasedArch = () =>
    <Training tag="Mid | Pro"
              title="Event based architectures"
              id="event-based-arch"
              imagesLeft={false}
              images={{
                  first: {src: ebaImg1, link: "", linkTitle: "", description: ""},
                  second: {src: ebaImg8, link: "", linkTitle: "", description: ""},
                  third: {src: ebaImg9, link: "", linkTitle: "", description: ""},
              }}
              description={<Fragment>
                  <p className="text-muted para-desc">Mindset for a flexible and robust microservices architecture.</p>
                  <p className="text-muted para-desc mb-0">The training wraps up all the Kafka features in a real-life event-based platform.</p>
              </Fragment>}>

        <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
            <Description description="The training is suited for developers that are already familiar with:"/>
            <Features features={prerequisites}/>
        </TrainingSection>

        <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
            <Description description="During the training the following topics will be covered:"/>
            <Features features={topics}/>
        </TrainingSection>

        <TrainingSection title={"Goals"} icon={"uil-check-square"}>
            <Description description="After completion a trainee should be familiar with know the principles, mechanisms,
        setup the environment and create a basic event-based setup from scratch."/>
        </TrainingSection>
    </Training>
