import React, {Fragment} from "react";
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import dockerCodeFirstImg1 from '../../../../assets/images/bootcamp/trainings/iac/docker-code-first-img1.jpg';
import dockerCodeFirstImg2 from '../../../../assets/images/bootcamp/trainings/iac/docker-code-first-img2.jpg';
import dockerCodeFirstImg3 from '../../../../assets/images/bootcamp/trainings/iac/docker-code-first-img3.jpg';

const prerequisites = [
    "GIT",
    "CI/CD",
    "A programming language",
    "Javascript"
]

const topics = [
    "Docker images, containers, files, volumes",
    "Docker port forwarding",
    "Docker networks",
    "Docker registries",
    "Docker compose & health-checks",
    "Code-First DB creation, migrations, seeds",
    "Code-First versioning",
    "Code-First logging and monitoring",
    "A full example",
]

export const DockerAndCodeFirst = () => <Training tag="Mid | Pro"
                                      id="docker"
                                      title="Docker & Code First"
                                      imagesLeft={true}
                                      images={{
                                          first: {src: dockerCodeFirstImg1, link: "", linkTitle: "", description: ""},
                                          second: {src: dockerCodeFirstImg2, link: "", linkTitle: "", description: ""},
                                          third: {src: dockerCodeFirstImg3, link: "", linkTitle: "", description: ""},
                                      }}
                                      description={<Fragment>
                                          <p className="text-muted para-desc">Ideal for teams that want to spin up fast
                                              the entire infrastructure using code-first approach.</p>
                                          <p className="text-muted para-desc mb-0">The <span
                                              className="text-primary fw-bold">Docker + Code-First</span> training is focused on
                                              how to design a replicable infrastructure and getting rid of complex system & app setup.</p>
                                      </Fragment>}>

    <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
        <Description description="The training is suited for developers that are already familiar with:" />
        <Features features={prerequisites}/>
    </TrainingSection>

    <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
        <Description description="During the training the following topics will be covered:" />
        <Features features={topics}/>
    </TrainingSection>

    <TrainingSection title={"Goals"} icon={"uil-check-square"}>
        <Description description="After completion a trainee should be able to containerize a deployment unit and spin it up." />
    </TrainingSection>

</Training>