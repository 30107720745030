import React from "react";
import { PopupButton } from "react-calendly";

export const ScheduleCall = ({scheduleCallLink, text}) => <PopupButton
    url={scheduleCallLink}
    rootElement={document.getElementById("root")}
    className="btn btn-primary m-1 calendly-button"
    text={text}
    pageSettings={{
        hideEventTypeDetails: true,
        hideLandingPageDetails: true,
    }}
/>
