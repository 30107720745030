import React from 'react'
import {Container} from 'reactstrap';
import {DockerAndCodeFirst} from "./trainings/docker-and-code-first";
import {K8sAndHelm} from "./trainings/k8s-and-helm";
import {CertificatesAndServiceMesh} from "./trainings/certificates-and-servicemesh";
import {Terraform} from "./trainings/terraform";
import {Ci} from "./trainings/ci";
import {MonitoringSystem} from "./trainings/monitoring-system";

export const Details = () => <section className="section">
    <Container>
       <DockerAndCodeFirst />
        <K8sAndHelm />
        <CertificatesAndServiceMesh />
        <Terraform />
        <Ci />
        <MonitoringSystem />
    </Container>
</section>