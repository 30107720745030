import React from 'react'
import {TrainingList} from "../shared/training-list";
import {avatars} from "../shared/avatars";

const trainings = [
    {
        id: 1,
        image: avatars.junior1,
        anchor: "#react-junior",
        title: "Enthusiastic Junior",
        autor: "SPAs, Components, State management, Effects, Contexts, Fetch API, Custom Hooks, React Router...",
        subtitle: "React Intro",
    },
    {
        id: 2,
        image: avatars.mid1,
        anchor: "#react-mid",
        title: "Passionate Mid",
        autor: "Memoization, Redux, RxJs, Redux Observable, Redux Persist, Axios, Apollo, Advanced React Router...",
    },
    {
        id: 3,
        image: avatars.senior1,
        anchor: "#react-pro",
        title: "Established Professional",
        autor: "Advanced component lifecycles, Suspense, Lazy Loading, Portals, Advanced DOM manipulation, SSR, SSG...",
    },
]
export const List = () => <TrainingList trainings={trainings}/>