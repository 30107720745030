import {Helmet} from "react-helmet";

export const Meta = () =>  <Helmet>
    {/*Primary Meta Tags*/}
    <title>React JS | Nerds Bootcamp</title>
    <meta name="title" content="React JS | Nerds Bootcamp" />
    <meta name="description" content="On-site edge-tech training programs for companies that need to make a shift in technology or take it to the next level" />
    <meta name="keywords" content="React Training, Romania, Nerds.sh, Nerds Bootcamp, React, Effects, Contexts, Hooks, Memoization, Redux, RxJs, Component Lifecycle, SSR, SSG" />

    {/*Open Graph / Facebook*/}
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://bootcamp.nerds.sh/react" />
    <meta property="og:title" content="React JS | Nerds Bootcamp" />
    <meta property="og:description" content="On-site edge-tech training programs for companies that need to make a shift in technology or take it to the next level" />
    <meta property="og:image" content="/react-preview.jpg" />
</Helmet>