import {Col, Container, Row} from "reactstrap";
import {routes} from "../routes";
import {ScheduleCall} from "../shared/components/schedule-call";

export const PostTraining = () => <section className="section bg-light">
    <Container className="mt-60">
        <Row className="justify-content-center">
            <Col xs={12}>
                <div className="section-title text-center pb-2">
                    <h4 className="title mt-3 mb-4">Post-Training Support</h4>
                </div>
            </Col>

            <Col lg={12}>
                <div className="flex-1 ms-2">
                    <p className="text-muted mx-auto">We know that the learning is an iterative process,
                        some things can be easily forgotten after an intensive 2 days bootcamp session
                        if knowledge is not applied fast. Because of this, we can support your team
                        to {" "}
                        <span className="text-primary fw-bold">
                        unblock in achieving its goals
                      </span>. The following weeks are crucial in fixing acquired information, when developers are on
                        their own can encounter different scenarios that slows them down. We can jump in
                        and help sticking on {" "}
                        <span className="text-primary fw-bold">
                        industry best-practices
                      </span>{" "} and {" "}
                        <span className="text-primary fw-bold">
                        advise on your actual projects
                      </span>. Because of this, we can schedule regular short meetings in the following weeks,
                        in which we answer the questions, design and come with proposals for the planned
                        features.
                    </p>

                </div>
                <div className="watch-video mt-4 pt-2 text-center">
                    <ScheduleCall scheduleCallLink={routes.scheduleCall.link} text="Get support" />
                </div>
            </Col>
        </Row>


    </Container>
</section>