import {useLocation} from "react-router-dom";
import React from "react";
import ReactGA from "react-ga4";
import { hotjar } from 'react-hotjar'

const usePageViews = () => {
    let location = useLocation()

    React.useEffect(
        () => {
            ReactGA.send({ hitType: "pageview", page: location.pathname });
            hotjar.stateChange(location.pathname);
        },
        [location]
    )
}

export const PageView = () => {

    usePageViews()

    return ''
}