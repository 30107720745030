import React from "react";
import {Container, Row} from "reactstrap";
import "../../../node_modules/react-image-lightbox/style.css";
import SectionTitle from "../../components/Shared/SectionTitle";
import CustomProcessBox from "./CustomProcessBox";

const steps = [
    {
        title: "Discovery",
        desc:
            "We start by listening on your needs and plans, understanding your team's background, direction and goals.",
    },
    {
        title: "Technical assessment",
        desc:
            "Several technical questions under the form of a questionnaire will be asked. That are useful for defining current know-how.",
    },
    {
        title: "Training plan",
        desc:
            "Based on previous steps, we will draw a training plan, focusing on the most important aspects.",
    },
    {
        title: "Agreement",
        desc:
            "We will discuss the plan together and adjust the agenda, set the timeframe and expectations.",
    },
    {
        title: "Actual training",
        desc:
            "The fun begins, there will be an intense Bootcamp session lead by our qualified trainers.",
        link: "#",
    },
    {
        title: "Evaluation & future steps",
        desc:
            "We collect the feedback and discuss further training recommendations or technical support in your projects.",
    },
]

const Description = () => <React.Fragment>
    Each {" "}
    <span className="text-primary fw-bold">Bootcamp</span>{" "}
    session is customized based on the trainees level and company needs. In this way we maximize the impact of each training.
</React.Fragment>

export const HowItWorksSteps = () => <Container>
    <SectionTitle title="Our approach" desc={<Description />} />
    <Row>
        <CustomProcessBox processes={steps}/>
    </Row>
</Container>