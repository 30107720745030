import junior1 from '../../../assets/images/bootcamp/trainings/featured/junior1.jpg';
import mid1 from '../../../assets/images/bootcamp/trainings/featured/mid1.jpg';
import senior1 from '../../../assets/images/bootcamp/trainings/featured/senior1.jpg';
import junior2 from '../../../assets/images/bootcamp/trainings/featured/junior2.jpg';
import mid2 from '../../../assets/images/bootcamp/trainings/featured/mid2.jpg';
import senior2 from '../../../assets/images/bootcamp/trainings/featured/senior2.jpg';

export const avatars = {
    junior1, mid1, senior1,
    junior2, mid2, senior2,
}