import React, {Fragment} from 'react'
import {TrainingSection} from "../../shared/training/training-section";
import {Features} from "../../shared/training/features";
import {Training} from "../../shared/training";
import {Description} from "../../shared/training/description";
import ebaImg1 from '../../../../assets/images/bootcamp/trainings/eba/eba-img1.jpg';
import ebaImg4 from '../../../../assets/images/bootcamp/trainings/eba/eba-img4.jpg';
import ebaImg5 from '../../../../assets/images/bootcamp/trainings/eba/eba-img5.jpg';

const prerequisites = [
    "Software architectures",
    "Microservices",
    "Kafka",
    "Coupling"
]

const topics = [
    "Logic for another service problem",
    "Logic for another service solution",
    "Connect overview",
    "Source connectors - source types, polling ways",
    "Sink connectors - target types, polling ways",
    "A full example"
]

export const KafkaConnect = () =>
    <Training tag="Mid | Pro"
              title="Kafka Connect"
              id="kafka-connect"
              imagesLeft={false}
              images={{
                  first: {src: ebaImg1, link: "", linkTitle: "", description: ""},
                  second: {src: ebaImg4, link: "", linkTitle: "", description: ""},
                  third: {src: ebaImg5, link: "", linkTitle: "", description: ""},
              }}
              description={<Fragment>
                  <p className="text-muted para-desc">Mandatory for services interaction.</p>
                  <p className="text-muted para-desc mb-0">The <span
                      className="text-primary fw-bold">Kafka-Connect</span> training is essential for giving real utility to an event-based setup.</p>
              </Fragment>}>

        <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
            <Description description="The training is suited for developers that are already familiar with:"/>
            <Features features={prerequisites}/>
        </TrainingSection>

        <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
            <Description description="During the training the following topics will be covered:"/>
            <Features features={topics}/>
        </TrainingSection>

        <TrainingSection title={"Goals"} icon={"uil-check-square"}>
            <Description description="After completion a trainee should be able to configure Kafka integrations."/>
        </TrainingSection>
    </Training>
