import React from "react";
import { Container } from "reactstrap";
import SectionTitle from "../../components/Shared/SectionTitle";
import ReviewsSlider from "../../components/Shared/ReviewsSlider";
import epix from "../../assets/images/bootcamp/testimonials/epix.jpg";
import beautilink from "../../assets/images/bootcamp/testimonials/user1.jpg";
import agilefreaks from "../../assets/images/bootcamp/testimonials/agilefreaks.jpg";
import okapi from "../../assets/images/bootcamp/testimonials/okapi.jpg";
import archibus from "../../assets/images/bootcamp/testimonials/archibus.jpg";
import expert from "../../assets/images/bootcamp/testimonials/expert.jpg";
import {routes} from "../routes";

const reviews = [
      {
        id: 1,
        img: epix,
        name: "Sacha Stanton",
        post: "Epix",
        desc:
            "Extremely knowledgeable, passionate about technology and coding techniques, and friendly to work with. They are a strong team and highly recommended.",
        rating: 5,
      },
      {
        id: 2,
        img: agilefreaks,
        name: "Adrian Nistor",
        post: "Agile Freaks",
        desc:
            "Over the years, Nerds Computing team became more and more robust and reliable, just like the systems they’re building! Thank you for your help.",
        rating: 5,
      },
      {
        id: 3,
        img: okapi,
        name: "Bogdan Marcu",
        post: "Okapi Studio",
        desc:
            "Experienced and highly professional. We’ve collaborated for over 6 months, on a complex enterprise application, and it went smooth, never missed a deadline.",
        rating: 5,
      },
      {
        id: 4,
        img: beautilink,
        name: "Horia Ivan",
        post: "BeautyLink",
        desc:
            "Powerful team with great diversity in terms of skills and knowledge in IT development. We cordially recommend the Ners Computing team.",
        rating: 5,
      },
      {
        id: 5,
        img: archibus,
        name: "Rahul Yadav",
        post: "Archibus",
        desc:
            "I worked with Nerds Computing on a very challenging project. Their professionalism recommend them as partner company for any project on software development.",
        rating: 5,
      },
      {
        id: 6,
        img: expert,
        name: "Adrian Miclaus",
        post: "ExpertRSVTI",
        desc:
            "I had the pleasure to work with a young team, highly qualified, which understood the all the requirements and further more they came up with great ideas.",
        rating: 5,
      },
    ];

export const Reviews = () => <section className="section bg-light">
  <Container>
    {/* section title */}
    <SectionTitle
        title="Testimonials"
        desc={<React.Fragment>What others think about {" "}
          <a href={routes.mainSite.link}><span className="text-primary fw-bold">Nerds</span></a> as a team.</React.Fragment>} />
    {/* clients slider */}
    <ReviewsSlider reviews={reviews} colClass="mt-4" />
  </Container>
</section>