import React, {Component} from 'react'

export default class ComingSoon extends Component {


    render() {
        return (
            <div style={{
                position: "fixed",
                width: "100%",
                height: "70px",
                zIndex: 999,
                bottom: 0,
                paddingTop: "10px",
                backgroundImage: "linear-gradient(135deg, #ff0000 25%, #ff4a4a 25%, #ff4a4a 50%, #ff0000 50%, #ff0000 75%, #ff4a4a 75%, #ff4a4a 100%",
                backgroundSize: "56.57px 56.57px",
                textAlign: "center",
                color: "white",
                fontSize: "36px",
                display: "none"
            }}>
                Coming soon
            </div>
        )
    }
}
