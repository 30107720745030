import React from 'react'
import {TrainingList} from "../shared/training-list";
import {avatars} from "../shared/avatars";

const trainings = [
    {
        id: 1,
        image: avatars.junior2,
        anchor: "#graphql-junior",
        title: "Enthusiastic Junior",
        autor: "Queries & Mutations, Fields, Directives...",
        subtitle: "GraphQL Intro",
    },
    {
        id: 2,
        image: avatars.mid2,
        anchor: "#graphql-mid",
        title: "Passionate Mid",
        autor: "Data loader, Node interface, Connection Node, Authorization...",
    },
    {
        id: 3,
        image: avatars.senior2,
        anchor: "#graphql-pro",
        title: "Established Professional",
        autor: "Apollo Router & Federation Schema...",
    },
]
export const List = () => <TrainingList trainings={trainings}/>