import React, {Fragment} from 'react'
import {Container} from 'reactstrap';

import juniorKitImg1 from '../../../assets/images/bootcamp/trainings/javascript/juniorKit-img1.jpg';
import juniorKitImg2 from '../../../assets/images/bootcamp/trainings/javascript/juniorKit-img2.jpg';
import juniorKitImg3 from '../../../assets/images/bootcamp/trainings/javascript/juniorKit-img3.jpg';

import {TrainingSection} from "../shared/training/training-section";
import {Features} from "../shared/training/features";
import {Training} from "../shared/training";
import {Description} from "../shared/training/description";

const prerequisites = [
    "Programming languages",
    "Software development",
    "C#",
    "HTML & CSS",
    "Javascript"
]

const topics = [
    "Design patterns",
    "OOP fundamentals",
    "SOLID principles",
    "REST overview & status codes",
    "GraphQL introduction",
    "Microservices concepts",
    "Hello World on .NET",
    "DOM & Javascript modules",
    "NodeJS key-points",
    "Hello World on React",
]

export const Details = () => <section className="section">
    <Container>
        <Training tag="Junior"
                  title=""
                  id="junior-kit"
                  imagesLeft={true}
                  images={{
                      first: {src: juniorKitImg3, link: "", linkTitle: "", description: ""},
                      second: {src: juniorKitImg1, link: "", linkTitle: "", description: ""},
                      third: {src: juniorKitImg2, link: "", linkTitle: "", description: ""},
                  }}
                  description={<Fragment>
                      <p className="text-muted para-desc">Ideal for new-entries that are familiar with
                          the basic software development concepts but have no experience in any modern
                          technologies.</p>
                      <p className="text-muted para-desc mb-0">The <span
                          className="text-primary fw-bold">junior</span> training is focused on
                          understanding in detail the essential software development concepts,
                          giving a starting point for the future development.</p>
                  </Fragment>}>

            <TrainingSection title={"Prerequisites"} icon={"uil-create-dashboard"}>
                <Description description="Suited for developers that have a basic knowledge about:"/>
                <Features features={prerequisites}/>
            </TrainingSection>

            <TrainingSection title={"Topics"} icon={"uil-bullseye"}>
                <Description description="During the training the following topics will be covered:"/>
                <Features features={topics}/>
            </TrainingSection>

            <TrainingSection title={"Goals"} icon={"uil-check-square"}>
                <Description description="After completion a trainee should be able to know the principles, mechanisms,
                setup the environment and create a basic .NET & React apps from scratch."/>
            </TrainingSection>
        </Training>
    </Container>
</section>